<template>
  <v-app @keyup.enter="resetPwd">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col v-if="dialog" cols="10" sm="8" md="4" lg="3">
            <v-card class="elevation-12">
              <v-card-title class="primary white--text"
                >SAC-Tourenverwaltung <br />Passwort zurücksetzten
              </v-card-title>
              <v-card-text>
                <v-text-field
                  name="pwd1"
                  v-model="pwd1"
                  required
                  label="Passwort"
                  tabindex="1"
                  type="password"
                />
                <v-text-field
                  name="pwd2"
                  v-model="pwd2"
                  required
                  label="Passwort wiederholen"
                  tabindex="2"
                  type="password"
                  :error-messages="errors"
                />
                <v-btn
                  success
                  block
                  color="success"
                  @click="resetPwd"
                  tabindex="3"
                  :loading="btnLoading"
                  :disabled="btnDisabled"
                  >Passwort ändern</v-btn
                >
              </v-card-text>
              <v-card-text>
                <router-link :to="{ name: 'Login' }">
                  Zurück zum Login
                </router-link>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authService from '@/services/auth'

export default {
  name: 'PWDReset',
  components: {},
  props: {},
  data() {
    return {
      pwd1: '',
      pwd2: '',
      errors: [],
      dialog: true,
      submitted: false,
      success: false,
    }
  },
  computed: {
    dataEntered() {
      return this.email !== ''
    },
    btnDisabled() {
      return this.success || this.submitted
    },
    btnLoading() {
      return this.submitted && !this.success
    },
    resetToken() {
      return this.$route.query.token
    },
  },
  methods: {
    resetPwd() {
      if (this.pwd1 !== this.pwd2) {
        this.errors = ['Passwörter stimmen nicht überein']
        return
      }
      this.submitted = true
      this.errors = []
      authService
        .pwdreset(this.$route.query.token, this.pwd1)
        .then(response => {
          console.log('New Password Set')
          this.$router.push({ name: 'Login' })
        })
        .catch(error => {
          const status = error.response.status
          if (status === 404) {
            this.errors = ['Fehler beim Passwort ändern']
          }
          this.success = false
          this.submitted = false
        })
    },
  },
}
</script>

<style scoped>
.card__title {
  display: block;
  text-align: center;
  font-size: 1.4em;
}
</style>
